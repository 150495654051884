import * as Sentry from '@sentry/react';

const formatError = (err) => {
  if (!err) {
    return new Error('Unknown error');
  }
  if (err instanceof Error) {
    return err;
  }
  if (typeof err === 'object') {
    const theError = new Error(err.message || JSON.stringify(err));
    Object.keys(err).forEach((key) => {
      theError[key] = err[key];
    });
    return theError;
  }

  return new Error(err.toString());
};

export default (err, data) => {
  const error = formatError(err);

  Sentry.captureException(error, {
    extra: {
      data: JSON.stringify(data),
    },
  });
};
