// @flow
import {
  camelCase,
  cloneDeep,
  forEach,
  isArrayLike,
  isDate,
  isEmpty,
  isObject,
  isString,
  isUndefined,
  snakeCase,
} from 'lodash';

// Transforms object keys to camel case
// eslint-disable-next-line import/prefer-default-export
export const toCamelCase = (data: any, skipKeys: Array<any> = []) => {
  const isArray = isArrayLike(data);
  const transformedData = isArray ? [] : {};

  forEach(data, (value, key) => {
    if (isArray) {
      transformedData.push(isObject(value) ? toCamelCase(value, skipKeys) : value);
    } else if (skipKeys.includes(key)) {
      transformedData[key] = value;
    } else {
      const transformedKey = !skipKeys.includes(key) ? camelCase(key) : key;
      transformedData[transformedKey] = isObject(value) ? toCamelCase(value, skipKeys) : value;
    }
  });

  return cloneDeep(transformedData);
};

/**
 * Converts request body attributes to underscore_style
 *
 * @param data
 * @returns {any}
 */
export const toSnakeCase = (data: any, skipKeys: Array<any> = []) => {
  const isArray = isArrayLike(data);
  const transformedData = isArray ? [] : {};

  if (isString(data) || isEmpty(data)) {
    return data;
  }

  forEach(data, (value, key) => {
    if (isArray) {
      transformedData.push(isObject(value) ? toSnakeCase(value) : value);
    } else if (skipKeys.includes(key)) {
      transformedData[key] = value;
    } else {
      const transformedKey = !skipKeys.includes(key) ? snakeCase(key) : key;

      transformedData[transformedKey] = isObject(value) && !isDate(value) ? toSnakeCase(value) : value;
    }
  });

  return transformedData;
};

/**
 * Toggle between adding and removing a class name from an element with JavaScript.
 * Provides cross-browser solution/fallback code for IE
 *
 * @param element
 * @param className
 * @param state
 */
export const toggleClass = (element: HTMLElement, className: string, state: boolean) => {
  const classes = element.className.split(' ');
  const index = classes.indexOf(className);

  if (index >= 0) {
    if (!state) {
      classes.splice(index, 1);
    }
  } else if (state || isUndefined(state)) {
    classes.push(className);
  }

  // eslint-disable-next-line no-param-reassign
  element.className = classes.join(' ');
};

export const scrollToError = (focus: boolean = true) => {
  const formGroup = document.querySelector('.has-error');

  if (formGroup) {
    formGroup.scrollIntoView();

    const field = formGroup.querySelector('input, textarea');

    if (field && focus) {
      field.focus();
    }
  }
};

/**
 * Turn base 64 image into a blob, so we can send it using multipart/form-data posts
 */
export const toBlob = (b64Data: string, contentType: string = '', sliceSize: number = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};
