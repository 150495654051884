import api from '../httpClient';

export class SettingsApi {
  #api = api;

  fetchSettings() {
    return this.#api.get('/v1/settings', { skipAuth: true });
  }
}

export default new SettingsApi();
