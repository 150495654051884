import axios from 'axios';
import idx from 'idx';
import * as Sentry from '@sentry/react';
import MobileDetect from 'mobile-detect';
import storage from 'local-storage-fallback';

// eslint-disable-next-line import/no-useless-path-segments
import window from '../../services/window';
import { toCamelCase, toSnakeCase } from '../../common/utils';
import { DEVICE } from '../../common/constants/device';
import config from '../../config';
import { ERROR_UNKNOWN } from '../../common/constants/errors';
import logError from '../logError';

const md = new MobileDetect(window.navigator ? window.navigator.userAgent : '');
// eslint-disable-next-line no-nested-ternary
const device = md.mobile() ? DEVICE.MOBILE_BROWSER : DEVICE.WEB;

// `transformRequest` allows changes to the request data before it is sent to the server
// This is only applicable for request methods 'PUT', 'POST', and 'PATCH'
// The last function in the array must return a string or an instance of Buffer, ArrayBuffer,
// FormData or Stream
// You may modify the headers object.
const transformRequest = (data) => (!data || data.transform === false ? data : toSnakeCase(data));

// `transformResponse` allows changes to the response data to be made before
// it is passed to then/catch
const transformResponse = (data) => toCamelCase(data);

const httpClient = axios.create({
  baseURL: config.apiEndpoint,
  headers: {
    'x-device': device,
    'x-origin': window.location.origin,
  },
  transformRequest: [transformRequest, ...axios.defaults.transformRequest],
  transformResponse: [...axios.defaults.transformResponse, transformResponse],
  responseType: 'json',
  // withCredentials: !config.isProduction,
});

/**
 * Get called with http response object
 * @param xhrConfig
 * @returns {*}
 */
export const onRequest = (xhrConfig) => {
  const newConfig = { ...xhrConfig };
  const token = storage.getItem('sessionToken');

  newConfig.headers['x-origin'] = window.location.origin;
  newConfig.headers['x-device'] = device;

  if (!xhrConfig.skipAuth && token) {
    newConfig.headers.Authorization = token;
  }

  return newConfig;
};

/**
 * Gets called when a previous interceptor threw an error or resolved with a rejection
 * @param error
 * @returns {Promise.<*>}
 */
export const onRequestError = (error) => Promise.reject(error);

/**
 * Gets called when the server responds regardless of the response status
 */
export const onResponse = () => {};

/**
 * Get called with http response object
 * @param response
 * @returns {*}
 */
export const onResponseSuccess = (response) => {
  onResponse();
  return response.data;
};

let refreshingPromise = null;
/**
 * Gets called when a previous interceptor threw an error or resolved with a rejection
 * @param error
 * @returns {Promise.<*>}
 */
export const onResponseError = async (error) => {
  const {
    response: { data, status },
    config: xhrConfig,
  } = error;
  const refreshToken = localStorage.getItem('refreshToken');
  const isRetryableError = xhrConfig && error.code !== 'ECONNABORTED' && (!error.response || data.retry);
  let customerSlug;

  try {
    customerSlug = JSON.parse(storage.getItem('redux')).session.segmentId;
  } catch (e) {
    customerSlug = null;
  }

  // If the response status is 401 Unauthorized, try to refresh the access token
  if (status === 401 && refreshToken && customerSlug && !xhrConfig.url.includes('refresh_token')) {
    try {
      if (refreshingPromise) {
        const response = await refreshingPromise;

        // eslint-disable-next-line no-param-reassign
        xhrConfig.headers.Authorization = response.sessionToken;
        return httpClient.request(xhrConfig);
      }

      refreshingPromise = httpClient.put(`/v1/customers/${customerSlug}/refresh_token`, {
        refreshToken,
      });
      const response = await refreshingPromise;

      refreshingPromise = null;
      // Save the new access and refresh tokens in localStorage
      storage.setItem('sessionToken', response.sessionToken);
      storage.setItem('refreshToken', response.refreshToken);

      // Re-send the original request with the new access token
      // eslint-disable-next-line no-param-reassign
      xhrConfig.headers.Authorization = response.sessionToken;

      try {
        if (xhrConfig.headers.Accept.includes('application/json')) {
          xhrConfig.data = JSON.parse(xhrConfig.data);
        }
      } catch (e) { /* empty */ }

      return httpClient.request(xhrConfig);
    } catch (refreshError) {
      // If the refresh token is invalid or expired, log the user out
      storage.clear();
      window.location.href = '/'; // redirect to home page
      return null;
    }
  }

  if (isRetryableError) {
    let retryCount = idx(xhrConfig, (_) => _.params.retryCount) || 0;
    const shouldRetry = isRetryableError && retryCount < config.api.retries;

    if (shouldRetry) {
      retryCount += 1;
      xhrConfig.url = xhrConfig.url.replace(xhrConfig.baseURL, '');
      xhrConfig.params.retryCount = retryCount;

      return new Promise((resolve) =>
        setTimeout(() => {
          resolve(httpClient(xhrConfig));
        }, config.api.retryDelay),
      );
    }

    if (data.retry) {
      const sentryData = { ...error };

      delete sentryData.config.data;

      Sentry.captureMessage(`Request failed after ${retryCount} automatic retries`, {
        level: 'error',
        extra: {
          error: sentryData,
        },
      });
    }

    data.error = ERROR_UNKNOWN;
  }

  onResponse();
  logError(error, data);
  return Promise.reject(error);
};

if (window.isBrowser) {
  // Add a request interceptor
  httpClient.interceptors.request.use(onRequest, onRequestError);
  // Add a response interceptor
  httpClient.interceptors.response.use(onResponseSuccess, onResponseError);
}

export default httpClient;
