import storage from 'local-storage-fallback';
// TODO: Remove this service
// eslint-disable-next-line import/no-mutable-exports
let win;

if (typeof window === 'undefined') {
  win = {
    localStorage: storage,
    sessionStorage: storage,
    location: {},
    addEventListener: () => {},
    removeEventListener: () => {},
  };
  win.isBrowser = false;
  win.isIframe = false;
} else {
  win = window;
  win.isBrowser = true;
  win.isIframe = win.self !== win.top;
}

export default win;
