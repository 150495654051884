import window from '../services/window';

const config = {
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT,
  storageKeys: {
    redux: 'redux',
    quoteId: 'quoteId',
    storageClearedKey: 'storageCleared',
    storageTabSyncKey: 'redux-tab-sync',
    hideIEWarning: 'hideIEWarning',
    currentQuestionnairePage: 'currentQuestionnairePage',
    quoteUnpurchasableContent: 'quoteUnpurchasableContent',
    questionId: 'questionId',
    returnUrl: 'returnUrl',
    isProducerEditQuote: 'isProducerEditQuote',
  },
  api: {
    retries: process.env.REACT_APP_API_RETRIES || 3,
    retryDelay: (process.env.REACT_APP_API_RETRY_DELAY || 2) * 1000,
  },
  quote: {
    secondsTillInactivityWarning: 60 * 28, // 28 minutes
    inactivityCountdownSeconds: 60 * 2, // 2 minutes
    addressAttrs: ['line1', 'line2', 'city', 'postcode', 'country', 'county', 'state'],
    disallowZipCodePrefixes: ['im', 'je', 'gy'], // restricted territories
  },
  secondsInactivityCountdownModal: 60 * 2, // seconds * minutes
  marketingSite: process.env.REACT_APP_MARKETING_URL || window.location.origin,
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_CLIENT_DSN,
    skipApiUrls: ['/api/customers/sign-in'],
  },
};

export default config;
