import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';

import window from './services/window';
import './assets/styles/style.scss';
import config from './config';
import settingsAPI from './services/api/settingsAPI';

const AppProvider = React.lazy(() => import('./AppProvider'));

Sentry.init({
  dsn: config.sentry.dsn,
  beforeSend(event, hint) {
    const error = hint.originalException;
    const responseURL = error?.request?.responseURL;
    let skipError = false;

    if (responseURL) {
      skipError = config.sentry.skipApiUrls.some((apiUrl) => responseURL.includes(apiUrl));
    }

    return skipError ? null : event;
  },
});

const startApp = async () => {
  const { payload: settings } = await settingsAPI.fetchSettings();

  // eslint-disable-next-line no-underscore-dangle
  window.__PRELOADED_STATE__ = { settings };

  const root = ReactDOM.createRoot(document.getElementById('main'));

  root.render(<AppProvider />);
};

startApp();
